import axios from 'axios'
import getSign from './getSign'
import Toastrs from './Toastrs';
import hostName from './host'
const service = axios.create({
    baseURL: hostName, // api的base_url
    timeout: 5000,
    
})

service.interceptors.request.use(config => {
    config.headers = {
        ...config.headers,
        // ...getSign(config)
    }
    return config
}, error => {
    Toastrs.error(error) // for debug
    Promise.reject(error)
})

service.interceptors.response.use(
    response => {
        const res = response.data;
        if (res.code === 201 || res.code === 400 || res.code === 401 || res.code === 403 || res.code === 404) {
            return Promise.reject('error');
        } else {
            if(res.code == 200 || res.code ==0){
                return res;
            }else{
                Toastrs.error(res.msg)
                return res;

            }
        }
    },
    error => {
        console.log('err' + error)// for debug
        return Promise.reject(error)
    }
)

export default service
