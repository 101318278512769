import request from '@/utils/request.js'
export async function login (data){
    return request({
        method: 'post',
        url: '/empl/login',
        data
    })
} 

export async function loginUser (data){
    return request({
        method: 'post',
        url: '/user/login',
        data
    })
} 


export async function sendCaptcha (data){
    return request({
        method: 'post',
        url: '/user/sendCaptcha',
        data
    })
} 