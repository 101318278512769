import md5 from 'js-md5'
const Appid = 305
const sign_key = 'f8ecb0c84d3f46b9'
function getSign(config) {
  let signObj = {
        'X-Auth-Appid': Appid,
        "X-Auth-Ts": parseInt(new Date()/1000),
        "X-Auth-Nonce": randomString(8),
      }
     
      let sarr = Object.keys(signObj).sort((a,b)=>{return a.toLowerCase()>b.toLowerCase()?1:-1;}).map((key, index)=>{
        return `${index ? '&':''}${key.toLowerCase()}=${signObj[key]}`
      }).join('')

      signObj['X-Auth-Sign'] = md5(sarr+sign_key).slice(5,15)
      signObj['X-Request-ID'] = randomString(32)
      
  
  return signObj
}

function randomString(len) {
 　　len = len || 32;
 　　var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  　　var maxPos = $chars.length;
  　　var pwd = '';
 　　for (let i = 0; i < len; i++) {
 　　　　pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
 　　}
 　　return pwd;
 }
export default getSign
